<template>
    <v-card class="docs serviceCZN">
        <v-card-title>
            <div class="text-h5 text-center">Создание карточки сервиса</div>
        </v-card-title>

        <table class="docs-table">
            <tbody>
            <tr>
                <td>
                    Наименование сервиса
                </td>
                <td>
                    <div class="standard-docs-params">
                        <div>
                            <div class="control">
                                <input type="text" placeholder=" " v-model="serviceName">
                                <label>Наименование сервиса</label>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Категория сервиса
                </td>
                <td>
                    <v-autocomplete
                            dense
                            :items="categories"
                            item-text="name"
                            v-model="serviceCategory"
                            label="Категория сервиса"
                            return-object
                    ></v-autocomplete>
                </td>
            </tr>
            </tbody>
        </table>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    class="btn btn-outline"
                    text
                    @click="$emit('close')"
            >
                Отмена
            </v-btn>
            <v-btn
                    class="btn btn-primary"
                    @click="$emit('close')"
            >
                Создать
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'ServiceCZNNewDialog',

        data: () => ({
            serviceName: '',
            serviceCategory: '',
            categories: [
                'Реестр прототипов сервисов',
                'Реестр сервисов для пилотной апробации',
                'Реестр рекомендуемых сервисов',
                'Реестр действующих сервисов',
                'Реестр архивных сервисов',
            ],
        }),
    }
</script>


