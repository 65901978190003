<template>
    <v-card class="docs serviceCZN">
        <v-card-title>
            <div class="text-h5 text-center">{{service.title}}</div>
        </v-card-title>
        <div class="subheader">{{service.category}}</div>

        <table class="docs-table">
            <tbody>
            <tr v-for="(field, fieldIndex) in fields" :key="'field' + fieldIndex">
                <td>
                    {{field.title}}
                </td>
                <td>
                    <template v-if="edit">
                        <template v-if="field.type==='text'">
                            <v-text-field></v-text-field>
                        </template>
                        <template v-else-if="field.type==='textarea'">
                            <v-textarea></v-textarea>
                        </template>
                        <template v-else-if="field.type==='autocomplete'">
                            <v-autocomplete
                                    dense
                                    :items="categories"
                                    item-text="name"
                                    return-object
                            ></v-autocomplete>
                        </template>
                    </template>
                    <template v-else-if="field?.isHref">
                        <a href="/">{{field.value}}</a>
                    </template>
                    <template v-else>
                        {{field.value}}
                    </template>
                </td>
            </tr>
            </tbody>
        </table>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                    class="btn btn-outline"
                    text
                    @click="$emit('close')"
            >
                Закрыть
            </v-btn>
            <v-btn
                    class="btn btn-outline"
                    text
                    @click="$emit('close')"
            >
                Отмена
            </v-btn>
            <v-btn
                    class="btn btn-primary"
                    @click="edit=false"
            >
                Создать
            </v-btn>
            <v-btn
                    class="btn btn-primary"
                    @click="edit=true"
            >
                Редактировать
            </v-btn>
            <v-btn
                    class="btn btn-primary"
                    @click="edit=false"
            >
                Сохранить изменения
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'ServiceCZNEditDialog',
        props: {
          service: Object,
        },

        data: () => ({
            edit: false,
            categories: [
                'Реестр прототипов сервисов',
                'Реестр сервисов для пилотной апробации',
                'Реестр рекомендуемых сервисов',
                'Реестр действующих сервисов',
                'Реестр архивных сервисов',
            ],
            fields: [
                // {
                //     title: 'Наименование сервиса',
                //     type: '',
                //     value: '',
                // },
                // {
                //     title: 'Краткое наименование сервиса',
                //     type: '',
                //     value: '',
                // },
                {
                    title: 'Категория сервиса',
                    type: 'autocomplete',
                    // list: this.categories,
                    value: 'Реестр действующий сервисов',
                },
                {
                    title: 'Услуга, в рамках которой предоставляется сервис',
                    type: 'text',
                    value: 'Поддержка при поиске работы и трудоустройстве',
                },
                {
                    title: 'Назначение сервиса',
                    type: 'textarea',
                    value: 'Предоставление информации, консультации и поддержки для населения, ищущего работу, с целью помочь им успешно трудоустроиться.',
                },
                {
                    title: 'Результаты реализации сервиса',
                    type: 'textarea',
                    value: 'Увеличение уровня занятости населения.\n' +
                    'Повышение профессиональной подготовки и навыков работников.\n' +
                    'Снижение безработицы и уровня социальной напряженности.\n' +
                    'Повышение экономической активности региона.',
                },
                {
                    title: 'Сроки реализации сервиса',
                    type: 'textarea',
                    value: 'Сервис предоставляется на постоянной основе и доступен всем желающим в течение года. Индивидуальные сроки реализации зависят от потребностей и целей клиента и могут варьироваться от нескольких недель до нескольких месяцев в зависимости от конкретных обстоятельств.\n',
                },
                {
                    title: 'Тег',
                    type: 'text',
                    value: '#трудоустройство',
                    isHref: true,
                },
            ],
        }),
    }
</script>


