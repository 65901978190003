<template>
    <div v-cloak>
        <transition name="fade" appear appear-active-class="fade-enter-active">
            <div v-if="apiLoaded || errorText">
                <div v-if="apiLoaded" class="wrap service-czn">

                    <div class="service-czn__header">
                        <div class="service-czn__filter">
                            <h1>Карта сервисов для ЦЗН</h1>
                            <div class="two-columns block">
                                <div class="left-element">

                                    <v-autocomplete
                                            dense
                                            label="Категория сервиса"
                                            :items="serviceList"
                                            item-text="name"
                                            return-object
                                    ></v-autocomplete>

                                    <div>
                                        <div class="control">
                                            <input type="text" placeholder=" ">
                                            <label>Поиск</label>
                                        </div>
                                    </div>

                                    <v-autocomplete
                                            dense
                                            label="Фильтры"
                                            :items="filterList"
                                            item-text="name"
                                            return-object
                                    ></v-autocomplete>

                                    <v-card-actions>
                                        <v-btn
                                                class="btn btn-primary"
                                                @click="$emit('close')"
                                        >
                                            Применить
                                        </v-btn>
                                        <v-btn
                                                class="btn btn-outline"
                                                text
                                                @click="$emit('close')"
                                        >
                                            Очистить
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                                <div class="right-element">
                                    <div class="title">Наиболее популярные теги:</div>
                                    <ul class="tag-list">
                                        <li><a href="/">#трудоустройство</a></li>
                                        <li><a href="/">#тег</a></li>
                                        <li><a href="/">#тег</a></li>
                                        <li><a href="/">#тег</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="service-czn__buttons">
                            <v-btn
                                    class="btn btn-primary"
                                    @click="$router.push('/serviceczn/config')"
                            >
                                Настроить состав<br>полей
                            </v-btn>

                            <v-dialog
                                    v-model="dialogNew"
                                    max-width="800px"
                                    persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            v-on="on"
                                            class="btn btn-primary"
                                    >
                                        Создать карту
                                    </v-btn>
                                </template>
                                <ServiceCZNNewDialog @close="dialogNew=false; dialogKey++"
                                                     :key="dialogKey"/>
                            </v-dialog>

                            <v-btn
                                    class="btn btn-outline"
                                    text
                                    @click="$emit('close')"
                            >
                                Экспорт статистики
                            </v-btn>

                        </div>
                    </div>

                    <div class="service-czn__list">
                        <div class="service-czn__item" v-for="(service, serviceIndex) in serviceList" :key="'serv' + serviceIndex">
                            <div class="service_czn__title">{{service.title}}</div>
                            <div class="service-czn__category">{{service.category}}</div>
                            <div class="service-czn__comment">{{service.comment}}</div>
                            <div class="service-czn__info">{{service.info}}</div>
                            <div class="service-czn__tag"><a href="/">{{service.tag}}</a></div>
                            <div class="service-czn__detail">
                                <a @click="serviceDialog(service)">Подробнее</a>
                                <div class="service-czn__del"></div>
                            </div>
                        </div>
                    </div>

                    <v-dialog
                            v-model="dialogEdit"
                            max-width="1000px"
                            persistent>
                        <template v-slot:activator="{ on }">
                            <div v-on="on">
                            </div>
                        </template>
                        <ServiceCZNEditDialog
                                @close="dialogEdit=false; dialogKey++"
                                :key="dialogKey"
                                :service="service"/>
                    </v-dialog>

                    <v-btn
                            v-if="values.status === 'DRAFT'"
                            class="push-right"
                            @click="sendReport()"
                    >
                        Отправить<br/>на рассмотрение
                    </v-btn>
                </div>
                <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
                    Ошибка: {{ errorText }}
                </div>
            </div>
            <div v-else style="height:1000px"></div>
        </transition>
    </div>
</template>

<script>
    import ServiceCZNNewDialog from "./dialogs/ServiceCZNNewDialog";
    import ServiceCZNEditDialog from "./dialogs/ServiceCZNEditDialog";

    export default {
        name: 'ServiceCZN',
        components: {
            ServiceCZNNewDialog,
            ServiceCZNEditDialog,
        },

        data: () => ({
            id: null,
            configId: null,
            config: {id: null},
            dialogKey: 0,
            dialogNew: false,
            dialogEdit: false,
            values: {},
            service: {},
            serviceList: [
                {
                    title: 'Профессиональная ориентация и трудоустройство',
                    category: 'Трудоустройство',
                    comment: 'Поддержка при поиске работы и трудоустройстве',
                    info: 'Предоставление информации, консультации и поддержки для населения, ищущего работу, с целью помочь им успешно трудоустроиться.',
                    tag: '#трудоустройство',
                },
                {
                    title: 'Наименование сервиса',
                    category: 'Краткое наименование сервиса',
                    comment: 'Услуга, в рамках которой предоставляется сервис',
                    info: 'Назначение сервиса',
                    tag: '#тег',
                },
                {
                    title: 'Наименование сервиса',
                    category: 'Краткое наименование сервиса',
                    comment: 'Услуга, в рамках которой предоставляется сервис',
                    info: 'Назначение сервиса',
                    tag: '#тег',
                },
                {
                    title: 'Наименование сервиса',
                    category: 'Краткое наименование сервиса',
                    comment: 'Услуга, в рамках которой предоставляется сервис',
                    info: 'Назначение сервиса',
                    tag: '#тег',
                },
                {
                    title: 'Наименование сервиса',
                    category: 'Краткое наименование сервиса',
                    comment: 'Услуга, в рамках которой предоставляется сервис',
                    info: 'Назначение сервиса',
                    tag: '#тег',
                },
                {
                    title: 'Наименование сервиса',
                    category: 'Краткое наименование сервиса',
                    comment: 'Услуга, в рамках которой предоставляется сервис',
                    info: 'Назначение сервиса',
                    tag: '#тег',
                },
                {
                    title: 'Наименование сервиса',
                    category: 'Краткое наименование сервиса',
                    comment: 'Услуга, в рамках которой предоставляется сервис',
                    info: 'Назначение сервиса',
                    tag: '#тег',
                },
            ],
            filterList: [],
            parametersTextPartMap: new Map(),
            parametersFilePartMap: new Map(),
            modifiedDate: new Map(),
            apiLoaded: false,
            errorText: null,
            editable: false,
            // dialog1: false,
            reportStatuses: {
                DRAFT: "Черновик",
                SENT: "Отправлено"
            },
        }),

        methods: {
            serviceDialog(service) {
                this.dialogEdit = true
                this.service = service
            }
        },

        filters: {},

        async beforeMount() {
            this.apiLoaded = true;
        }
    }
</script>

<style lang="scss">
    @import "../styles/main.scss";
</style>
